import React, { useEffect, useState } from 'react'
import '../../styles/LoginForm.css'
import '../../styles/Assessment.css'
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
const ViewReport = () => {
    const { assessments } = useSelector(state => state.Assess)
    const { user } = useSelector(state => state.Auth)

    const id = useParams().id

    const [assessment, setAssessment] = useState({})
    const [assessContent, setAssessContent] = useState({})

    useEffect(() => {
        const assess = assessments.find(assess => assess._id === id)
        setAssessment(assess)
        if (assess?.reportType === 'long') {
            setAssessContent(assess?.longSummary)
        }
        else if (assess?.reportType === 'short') {
            setAssessContent(assess?.shortSummary)
        }
    }
        , [assessments, id])


    const removeUnderScore = (key) => {
        return key.replace(/_/g, ' ')
    }

    return (
        <div className="section">
            <div className="container-fluid">
                <div className="d-flex justify-content-between mb-4">
                    <div className="">
                        <div className="d-flex">
                            <Link to='/completed-reports'>
                                <i className='bx bx-chevron-left back-btn'></i>
                            </Link>
                            <span>
                                <h1>{assessment?.patientId?.firstName} {assessment?.patientId?.lastName}</h1>
                            </span>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container">
                <div className='mb-5'>
                    <h5 className='mb-3'><b>Name: </b>{assessment?.patientId?.firstName} {assessment?.patientId?.lastName} </h5>
                    <h5 className='mb-3'><b>Year of Birth: </b>{assessment?.userDetails?.yearOfBirth}</h5>
                    <h5 className='mb-3'><b>Age: </b>{new Date().getFullYear() - assessment?.userDetails?.yearOfBirth}</h5>
                    <h5 className='mb-3'><b>Assessed By: </b>{user?.firstName} {user?.lastName}</h5>
                    <h5 className='mb-3'><b>Assessment Date: </b>{new Date(assessment?.updatedAt).toLocaleDateString()}</h5>
                </div>

                {
                    assessContent && <div className="assessment">
                        {
                            Object.keys(assessContent)?.map((key, index) => {
                                return (
                                    <div className="my-2" key={index}>
                                        <div className="assessment-content-key mb-1" id={`key-${index}`}>

                                            {removeUnderScore(key)}
                                        </div>
                                        <div className="assessment-content-value" id={`content-${index}`}>
                                            <p className='report-content'>
                                                {
                                                    typeof assessContent[key] === 'object' ?
                                                        assessContent[key]?.map((item, index) => {
                                                            return (
                                                                <span key={index}>
                                                                    {item}
                                                                    <br />
                                                                </span>
                                                            )
                                                        })
                                                        :
                                                        assessContent[key]
                                                }
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default ViewReport