import React, { useEffect, useState } from 'react'
import '../../styles/LoginForm.css'
import '../../styles/Assessment.css'
import { useSelector, useDispatch } from 'react-redux';
import { updateAssessment } from '../../app/actions/assessActions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const EditPendingReport = () => {
    const id = useParams().id

    const dispatch = useDispatch()
    const redirect = useNavigate()

    const { forms } = useSelector(state => state.Form)
    const { assessments } = useSelector(state => state.Assess)
    const { user } = useSelector(state => state.Auth)

    const [formsLimit, setFormsLimit] = useState(0)
    const [assessment, setAssessment] = useState({
        firstName: '',
        lastName: '',
        email: '',
        formIds: []
    })
    const [eatingDisorderForm, setEatingDisorderForm] = useState([]);
    const [eatingDisorderSubForms, setEatingDisorderSubForms] = useState([])

    useEffect(() => {
        const tierOne = forms?.filter(f => f.tier === 'tier-1')
        const mcsds = forms?.filter(f => f.formName === 'MC-SDS')
        const pid = forms?.filter(f => f.formName === 'PID-5')
        setFormsLimit(tierOne.length + mcsds.length + pid.length)
        setEatingDisorderForm(forms.filter((disorder) => disorder.formName === "Eating Disorder"));
        setEatingDisorderSubForms(forms.filter((disorder) =>
            disorder.formName === "Eating Disorder - Anorexia Nervosa" ||
            disorder.formName === "Eating Disorder - Avoidant/Restrictive Food Intake Disorder" ||
            disorder.formName === "Eating Disorder - Binge Eating Disorder" ||
            disorder.formName === "Eating Disorder - Bulimia Nervosa" ||
            disorder.formName === "Eating Disorder - Supplementary Information for Eating Disorders"
        ));
        // eslint-disable-next-line
    }, [forms])

    useEffect(() => {
        const assess = assessments.find(assessment => assessment._id === id)
        if (assess) {
            setAssessment({
                firstName: assess.patientId.firstName,
                lastName: assess.patientId.lastName,
                email: assess.patientId.email,
                formIds: assess.formIds?.map(form => form._id)
            })
        }
        // eslint-disable-next-line
    }, [assessments])

    const checkboxes = document.querySelectorAll('.form-check-input')
    checkboxes.forEach(checkbox => {
        if (assessment.formIds.includes(checkbox.id)) {
            checkbox.checked = true
        }
    })


    const handleInputChange = (e) => {
        setAssessment({ ...assessment, [e.target.name]: e.target.value })
    }

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            if (user.planId === 'assess-bronze-monthly' && assessment.formIds.length >= (formsLimit + 5)) {
                toast.error('You can only select 5 forms to send. Upgrade your plan to select more forms')
                e.target.checked = false
                return
            }
            if (e.target.id === eatingDisorderForm[0]?._id) {
                setFormsLimit(formsLimit + eatingDisorderSubForms.length)
                setAssessment({ ...assessment, formIds: [...assessment.formIds, eatingDisorderForm[0]?._id, ...eatingDisorderSubForms.map(disorder => disorder?._id)] })
            }
            else {
                setAssessment({ ...assessment, formIds: [...assessment.formIds, e.target.id] })
            }
        }
        else {
            if (e.target.id === eatingDisorderForm[0]?._id) {
                setFormsLimit(formsLimit - eatingDisorderSubForms.length)
                setAssessment({ ...assessment, formIds: assessment.formIds?.filter(id => id !== eatingDisorderForm[0]?._id && !eatingDisorderSubForms.map(disorder => disorder?._id).includes(id)) })
            }
            else {
                setAssessment({ ...assessment, formIds: assessment.formIds?.filter(id => id !== e.target.id) })
            }
        }
    }

    const redirectToDashboard = () => {
        redirect('/pending-reports')
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (assessment.firstName === '' || assessment.lastName === '' || assessment.email === '') {
            toast.error('Please fill all fields')
        }
        else if (assessment.formIds.length === 0) {
            toast.error('Please select at least one assessment')
        }
        else {
            dispatch(updateAssessment(assessment, id, redirectToDashboard))
        }
    }

    const handleSearch = (e) => {
        const search = e.target.value.toLowerCase()
        const forms = document.querySelectorAll('.assessment-item')
        forms.forEach(form => {
            const formName = form.querySelector('.form-check-label').textContent.toLowerCase()
            if (formName.includes(search)) {
                form.style.display = 'block'
            }
            else {
                form.style.display = 'none'
            }
        })
    }

    const selectAllTierTwo = (e) => {
        const tierTwo = document.querySelectorAll('.assessment-item .tier-two');
        if (e.target.checked) {
            tierTwo.forEach(form => {
                form.checked = true;
                setAssessment(prevState => ({
                    ...prevState,
                    formIds: [...prevState.formIds, form.id]
                }));

            });
        } else {
            tierTwo.forEach(form => {
                form.checked = false;
                setAssessment(prevState => ({
                    ...prevState,
                    formIds: prevState.formIds?.filter(id => id !== form.id)
                }));
            });
        }
    };


    // useEffect(() => {
    //     const tierTwoCheckboxes = document.querySelectorAll('.tier-two')
    //     const selectAll = document.querySelector('.select-two')
    //     tierTwoCheckboxes.forEach(checkbox => {
    //         checkbox.addEventListener('change', () => {
    //             if (!checkbox?.checked) {
    //                 selectAll.checked = false
    //             }
    //         })
    //     })
    // }, [assessment])


    return (
        <div className="section">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-md-6">
                        <div className="d-flex">
                            <Link to='/pending-reports'>
                                <i className='bx bx-chevron-left back-btn'></i>
                            </Link>
                            <span>
                                <h1>Update Assessment</h1>
                                <p>We have created a list of assessments for you to assign to your patients</p>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="input-group">
                            <input type="text" placeholder="Search Assessment (by name)" className="form-input" name='search' onChange={handleSearch} />
                            <i className='bx bx-search input-icon'></i>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <button className="send-btn header-btn btn-login" onClick={handleSubmit}>
                            Update
                        </button>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="input-group">
                            <input type="text" placeholder="First Name" className="form-input" name='firstName' onChange={handleInputChange} value={assessment.firstName} />
                            <i className='bx bx-user input-icon'></i>
                        </div>
                        <div className="input-group">
                            <input type="text" placeholder="Last Name" className="form-input" name='lastName' onChange={handleInputChange} value={assessment.lastName} />
                            <i className='bx bx-user input-icon'></i>
                        </div>
                        <div className="input-group">
                            <input type="email" placeholder="Email (Example@login.com)" className="form-input" name='email' onChange={handleInputChange} value={assessment.email} />
                            <i className='bx bx-envelope input-icon'></i>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-6">
                        <h4>Choose Assessment(s) to assign</h4>
                        <div className="assements-form">
                            <div className="assessment-item">
                                <div className="form-check mb-2">
                                    <input type="checkbox" className="form-check-input" checked disabled />
                                    <label className="form-check-label" htmlFor='tier1'>DART Tier 1</label>
                                </div>
                            </div>
                            <div className="assessment-item">
                                <div className="form-check mb-2">
                                    <input type="checkbox" className="form-check-input" checked disabled />
                                    <label className="form-check-label" htmlFor='mcsds'>MC-SDS</label>
                                </div>
                            </div>
                            <div className="assessment-item">
                                <div className="form-check mb-2">
                                    <input type="checkbox" className="form-check-input" checked disabled />
                                    <label className="form-check-label" htmlFor='pid'>PID - 5</label>
                                </div>
                            </div>
                            {
                                forms?.filter(form => (form.tier === 'individual' && form.formName !== 'MC-SDS' && form.formName !== 'PID-5'))?.map(form => (
                                    <div className="assessment-item" key={form?._id}>
                                        <div className="form-check mb-2">
                                            <input type="checkbox" className="form-check-input" id={form?._id} onChange={handleCheckboxChange} />
                                            <label className="form-check-label" htmlFor={form?._id}>{form?.formName}</label>
                                        </div>
                                    </div>
                                ))
                            }
                            {/* <div className='d-flex align-items-center tier-head'>
                                <input type="checkbox" className="form-check-input tier-two select-two" />
                                <h5 className='mb-0 mx-2'></h5>
                            </div> */}

                            <div className="assessment-item">
                                <div className="form-check mb-2">
                                    {
                                        user.planId === 'assess-bronze-monthly' ? <></> : <input type="checkbox" className="form-check-input" id='dart2' onChange={selectAllTierTwo} />
                                    }
                                    <label className="form-check-label" htmlFor='dart2'>DART Tier 2</label>
                                </div>
                            </div>

                            <div className='' id='tier-2'>
                                {
                                    forms?.filter(form =>
                                        form.tier === 'tier-2' &&
                                        !eatingDisorderSubForms.map(disorder => disorder?.formName).includes(form?.formName)
                                    )?.map(form => (
                                        <div className="assessment-item" key={form?._id}>
                                            <div className="form-check mb-2">
                                                <input type="checkbox" className="form-check-input tier-two" id={form?._id} onChange={handleCheckboxChange} />
                                                <label className="form-check-label" htmlFor={form?._id}>{form?.formName}</label>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPendingReport