import { React, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Plan from './pages/Plan';
import Assessment from './pages/Assesment';
import HeaderLayout from './components/HeaderLayout';
import Protected from './components/Protected';
import { Toaster } from 'react-hot-toast';
import Payment from './pages/Payment';
import Page404 from './components/404/Page404';
import VerifyUser from './pages/VerifyUser';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from './app/actions/authActions';
import { getForms } from './app/actions/formActions';
import { getAssessments, resetTimeLimit } from './app/actions/assessActions';
import EditPendingReport from './pages/Reports/EditPendingReport';
import ReviewReport from './pages/Reports/ReviewReport';
import ApprovedReportList from './pages/Reports/ApprovedReportList';
import ViewReport from './pages/Reports/ViewReport';
import Home from './components/404/Home';
import PendingReportDetails from './pages/Reports/PendingReportDetails';
import CompletedReportDetails from './pages/Reports/CompletedReportDetails';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const Profile = lazy(() => import('./pages/Profile'));
const PendingReportList = lazy(() => import('./pages/Reports/PendingReportList'));

function App() {
  console.log("Build Date: 19-June-2024")

  const { isAuthenticated, user } = useSelector(state => state.Auth)
  const dispatch = useDispatch()


  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserToken())
      dispatch(getForms())
      dispatch(getAssessments())

      if (user.planId === 'assess-bronze-monthly' || user.planId === 'assess-silver-monthly-psychologist' || user.planId === 'assess-silver-yearly-psychologist') {
        dispatch(resetTimeLimit())
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated])






  return (
    <>
      <Router>
        <Suspense fallback={<>Loading...</>}>
          <Routes>
            <Route element={<HeaderLayout />}>
              <Route path='/plans' element={<Plan />} />
              {/* <Route path='/completed-reports' element={<ReportList />} /> */}
              <Route path='/assessment' element={<Assessment />} />
              <Route path='/profile' element={<Protected><Profile /></Protected>} />
              <Route path='/dashboard' exact element={<Dashboard />} />
              <Route path='/verify-user' element={<VerifyUser />} />
              <Route path='/payment' element={<Protected><Payment /></Protected>} />
              <Route path='/pending-reports' element={<PendingReportList />} />
              <Route path='/pending-reports/details/:id' element={<PendingReportDetails />} />
              <Route path='/completed-reports/details/:id' element={<CompletedReportDetails />} />
              <Route path='/completed-reports' element={<ApprovedReportList />} />
              <Route path='/pending-reports/:id' element={<EditPendingReport />} />
              <Route path='/review-report/:id' element={<ReviewReport />} />
              <Route path='/view-report/:id' element={<ViewReport />} />

              <Route path='/' element={<Home />} />
              <Route path='*' element={<Page404 />} />
            </Route>
          </Routes>
        </Suspense>
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
      </Router>
    </>
  );
}

export default App;
